<template>
  <div class="navigation-panel">
    <router-link 
      to="/friends"
      id="friends"
      class="nav-button" 
      :class="{ active: isActive === 'friends' }"
      @click="handleClick('friends')">
      <img class="friends-icon" :src="require('@/assets/navigation/friends-icon.png')" />
      <p>Friends</p>
    </router-link>

    <router-link 
      to="/"
      id="main"
      class="nav-button" 
      :class="{ active: isActive === 'main' }"
      @click="handleClick('main')">
      <img class="box-icon" :src="require('@/assets/navigation/box-icon.png')" />
      <p>Box</p>
    </router-link>

    <router-link 
      to="/wallet"
      id="wallet"
      class="nav-button" 
      :class="{ active: isActive === 'wallet' }"
      @click="handleClick('wallet')">
      <img class="wallet-icon" :src="require('@/assets/navigation/wallet-icon.png')" />
      <p>Wallet</p>
    </router-link>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const isActive = ref(route.name?.toLowerCase());

// Следим за изменением маршрута
watch(
  () => route.name,
  (newRoute) => {
    if (newRoute) {
      isActive.value = newRoute.toLowerCase();
    }
  },
  { immediate: true }
);

// Обработчик клика
const handleClick = (page) => {
  isActive.value = page;
  triggerHapticFeedback(); // Дополнительное действие
};

// Функция обратной связи
const triggerHapticFeedback = () => {
  if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
    window.Telegram.WebApp.HapticFeedback.impactOccurred("soft");
  } else {
    console.warn("Haptic feedback is not supported or WebApp.HapticFeedback is not available.");
  }
};
</script>

<style scoped>

.navigation-panel {
  position: absolute;
  bottom: 34px;
  left: 50%;
  transform: translateX(-50%);
  width: 278px;
  height: 76px;
  backdrop-filter: blur(64px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 20px;
  gap: 2px;
}

.nav-button img {
  width: 28px;
  height: 28px;
  opacity: 0.5;
}

.nav-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 87.33px;
  height: 64px;
  border-radius: 12px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;
}

.nav-button.active img {
  opacity: 1;
}

.nav-button.active{
  background: rgba(13, 19, 31, 0.28);
  color: #FFFFFF;
}

</style>