<template>

    <BackButton/>

    <div class="pay-container">
        <div class="pay-line">
            <p class="pay-title">Open more boxes</p>
        </div>
        <PricingOptionCard
            v-for="card in cards"
            :key="card.id"
            :selected="card.selected"
            :boxes-count="card.boxesCount"
            :price="card.price"
            :img-count="card.imgCount"
            :old-price="card.oldPrice"
            @click="selectCard(card.id)"
        />
        <div class="pay-button" @click="createInvoice">
            <p class="pay-button-text">Pay {{ cards.find(card => card.selected).price }}</p>
            <img class="pay-icon" :src="require('@/assets/pricing-option-card/star-icon-white.png')" />
        </div>        
    </div>

</template>

<script setup>
import PricingOptionCard from '@/components/PricingOptionCard.vue';
import BackButton from '../components/BackButton.vue';
import { ref } from 'vue';
import axios from '@/utils/axiosConfig';
import { useWebAppNavigation } from 'vue-tg';
import { triggerHapticFeedback } from '@/utils/hapticFeedback';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const router = useRouter();

const cards = ref([
    { id: 1, boxesCount: 5, oldPrice: 10000, price: 5000, imgCount: 1, selected: true },
    { id: 2, boxesCount: 10, oldPrice: 20000, price: 10000, imgCount: 2, selected: false },
    { id: 3, boxesCount: 20, oldPrice: 40000, price: 20000, imgCount: 3, selected: false },
    { id: 4, boxesCount: 50, oldPrice: 80000, price: 1, imgCount: 3, selected: false },
]);

function getTelegramId() {
    if (window.Telegram && window.Telegram.WebApp) {
        return window.Telegram.WebApp.initDataUnsafe?.user?.id || null;
    }
    return null;
}

async function createInvoice() {
    triggerHapticFeedback("light", "impact");
    const telegramId = getTelegramId();
    try {
        const selectedCard = cards.value.find(card => card.selected);

        const response = await axios.post('/create-invoice/', {
            telegram_id: telegramId,
            amount: selectedCard.price,
            boxes_count: selectedCard.boxesCount,
            description: `Оплата за ${selectedCard.boxesCount} коробок`,
        });

        if (response.data.ok) {
            const invoiceLink = response.data.invoice_link;

            useWebAppNavigation().openInvoice(invoiceLink, (status) => {
                switch (status) {
                    case "paid":
                        triggerHapticFeedback("success");
                        store.dispatch('fetchBoxesCount');
                        router.push({ name: 'Main' });
                        console.log("Оплата прошла успешно! Спасибо за покупку.");
                        break;
                    case "cancelled":
                        console.log("Оплата была отменена.");
                        break;
                    case "failed":
                        console.log("Произошла ошибка при оплате.");
                        break;
                    case "pending":
                        console.log("Оплата находится в ожидании. Пожалуйста, проверьте позже.");
                        break;
                    default:
                        console.warn(`Неизвестный статус: ${status}`);
                }
            });
        } else {
            console.log(`Ошибка при создании платежа: ${response.data.error}`);
        }
    } catch (error) {
        console.error('Ошибка при создании платежа:', error);
        console.log('Не удалось создать платёж. Попробуйте снова.');
    }
}

function selectCard(cardId) {
    cards.value.forEach(card => {
        card.selected = card.id === cardId;
    });
}
</script>

<style scoped>

.pay-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-top: 20px;
    padding: 0px 10px;
    height: calc(100% - 100px);
}

.pay-line {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
}

.pay-title {
    color: #FFFFFF;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;
}

.pay-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 16px 0px;
    border: none;
    border-radius: 12px;
    background: #6C47FF;
    width: calc(100% - 40px);
    margin-top: auto;
}

.pay-button-text {
    color: #FFFFFF;
    font-size: 17px;
    font-weight: 590;
}

.pay-icon {
    width: 20px;
}

</style>