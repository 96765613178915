// axiosConfig.js

import axios from 'axios';
import { getCSRFToken } from './csrf';

// Создаём экземпляр axios
const axiosInstance = axios.create({
    baseURL: '/api/', 
    headers: {
        'Content-Type': 'application/json',
    },
});

// Устанавливаем CSRF-токен
const csrfToken = getCSRFToken();
if (csrfToken) {
    axiosInstance.defaults.headers.common['X-CSRFToken'] = csrfToken;
}

// Обработчик запросов
axiosInstance.interceptors.request.use(
    (config) => {
        const csrfToken = getCSRFToken(); // Получаем актуальный токен
        if (csrfToken) {
            config.headers['X-CSRFToken'] = csrfToken; // Устанавливаем токен в заголовок
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Обработчик ответов
axiosInstance.interceptors.response.use(
    (response) => {
        // Можно обработать успешные ответы
        return response;
    },
    (error) => {
        // Обработка ошибок
        console.error('Response Error:', error);
        return Promise.reject(error);
    }
);

export default axiosInstance;
