import { createRouter, createWebHistory } from 'vue-router'
import MainPage from '../views/MainPage.vue'
import WalletPage from '../views/WalletPage.vue'
import FriendsPage from '@/views/FriendsPage.vue'
import PayPage from '@/views/PayPage.vue'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: MainPage,
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: WalletPage
  },
  { path: '/friends',
    name: 'Friends',
    component: FriendsPage
  },
  {
    path: '/pay',
    name: 'Pay',
    component: PayPage
  }
  ]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
