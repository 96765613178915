import axios from '../../utils/axiosConfig';

export default {
  namespaced: true,
  state: {
    friends: [],
    freeBoxesLeft: 0,
  },
  getters: {
    friends: (state) => state.friends,
    freeBoxesLeft: (state) => 5 - state.friends.length,
  },
  mutations: {
    SET_FRIENDS(state, friends) {
      state.friends = friends;
    },
  },
  actions: {
    async fetchFriends({ commit }) {
      try {
        const response = await axios.get('/friends/', {

        });
        commit('SET_FRIENDS', response.data.friends);
      } catch (error) {
        console.error('Error fetching friends:', error);
      }
    },
  },
};
