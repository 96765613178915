<template>

    <div class="card" :class="{'selected-card': props.selected}" @click="handleClick">
        
        <div class="card-left">

            <div v-if="props.selected" class="selected">
                <img class="selected-icon" :src="require('@/assets/pricing-option-card/selected-icon.png')" alt="Selected">
            </div>

            <div v-else class="not-selected">
            </div>

            <div class="boxes-info">
                <div class="boxes-imgs">
                    <img class="box-icon" v-for="i in props.imgCount" :key="i" :src="require('@/assets/pricing-option-card/box-icon.png')" alt="Box">
                </div>

                <div class="boxes-text">
                    <p>for {{ props.boxesCount }} boxes</p>
                </div>
            </div>

        </div>

        <div class="card-right">
            <div class="old-price">
                <p class="old-price-text">{{ props.oldPrice }}</p>
                <img class="old-price-icon" :src="require('@/assets/pricing-option-card/old-star-icon.png')" alt="Old price">
                <div class="strike-through"></div>
            </div>
            <div class="new-price">
                <p class="boxes-price">{{ props.price }}</p>
                <img class="star-icon" :src="require('@/assets/pricing-option-card/star-icon.png')" alt="Star">
            </div>
        </div>

    </div>

</template>


<script setup>
import { defineProps, defineEmits } from 'vue';

const emit = defineEmits(['click']);

const props = defineProps({
    selected: Boolean,
    price: String,
    boxesCount: Number,
    imgCount: Number,
    oldPrice: String
})

const triggerHapticFeedback = () => {
  if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
    window.Telegram.WebApp.HapticFeedback.impactOccurred("medium");
  } else {
    console.warn("Haptic feedback is not supported or WebApp.HapticFeedback is not available.");
  }
};

const handleClick = () => {

    if (props.selected) {
        return;
    }

    triggerHapticFeedback();
    emit("click");
};

</script>

<style scoped>

.old-price {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 4px;
    position: relative;
}

.new-price {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 6px;
}

.boxes-price {
    color: #FFFFFF;
    font-size: 19px;
    font-weight: 590;
}

.star-icon {
    width: 20px;
}

.old-price .strike-through {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-top: 1px solid #92949A;
    transform: rotate(-15deg);
    pointer-events: none;
}

.old-price-icon {
    width: 13.33px;
}

.old-price-text {
    color: #FFFFFF80;
    font-size: 13px;
    font-weight: 400;
}

.card {
    width: calc(100% - 30px);
    background: #FFFFFF1A;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    padding: 20px 16px;
}

.card.selected-card {
    background: #6C47FF33;
}

.card-left {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
}

.selected {
    border-radius: 50%;
    width: 24px;
    height: 24px;
}

.selected-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.not-selected {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #FFFFFF1A;
}

.boxes-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
}

.boxes-imgs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.boxes-imgs img:first-of-type {
    margin-left: 0px;
}

.box-icon {
    width: 24px;
    height: 24px;
    margin-left: -8px;
}

.boxes-text {
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 590;
}

.card-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
}

</style>