<template>
<header>

    <div class="left-side">
        <router-link to="pay" custom v-slot="{ navigate }">
            <div 
                @click="handleClick(navigate)" 
                :class="{'boxes': true, 'boxes-empty': boxesCount === 0, 'boxes-filled': boxesCount > 0}"
            >
                <img class="box-icon" :src="require('@/assets/box-icon.png')" />
                <p>{{ boxesCount }} boxes</p>
                <div class="add-circle">
                    <img class="plus-icon" :src="require('@/assets/plus-icon.png')" />
                </div>
            </div>
        </router-link>
        
        <div class="telegram-group">
            <img class="telegram-icon" :src="require('@/assets/telegram-icon.png')" />
            <p>Telegram</p>
        </div>
    </div>

</header>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { triggerHapticFeedback } from '@/utils/hapticFeedback';

const store = useStore();
const boxesCount = computed(() => store.getters.boxesCount);

function handleClick(navigate) {
    triggerHapticFeedback("light", "impact");
    navigate();
}
</script>

<style scoped>
header {
    justify-content: flex-start !important; 
    padding: 8px 16px;
}

.boxes, .add-circle, header, .telegram-group, .left-side {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.left-side {
    gap: 4px;
}

.boxes, .telegram-group {
    max-height: 32px;
}

.boxes { 
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)),
    linear-gradient(90deg, rgba(108, 71, 255, 0) 50%, rgba(108, 71, 255, 0.4) 100%);
    border-radius: 30px;
    border: 1px solid #6C47FF;
    gap: 8px;
}

.boxes p {
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 590;
}

.box-icon {
    height: 17px;
    margin: 8px 0px 8px 8px;
}

.add-circle {
    border-radius: 50%;
    background: #6C47FF;
    padding: 6.25px;
    margin: 6px 6px 6px 0px;
}

.telegram-group {
    padding: 8px;
    gap: 8px;
    backdrop-filter: blur(64px);
    background: #FFFFFF1F;
    border-radius: 30px;
}

.telegram-group p {
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 590;
}

.plus-icon {
    width: 7.5px;
    height: 7.5px;
}

.telegram-icon {
    width: 16px;
    height: 13px;
}


</style>
