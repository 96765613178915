<template>

    <div class="back-button-container" @click="handleClick">    
        <div class="back-button">
            <img class="back-icon" :src="require('@/assets/arrow-icon.png')" />
            <p>Back</p>
        </div>
    </div>

</template>

<script setup>
import router from '@/router';

const triggerHapticFeedback = () => {
  if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
    window.Telegram.WebApp.HapticFeedback.impactOccurred("soft");
  } else {
    console.warn("Haptic feedback is not supported or WebApp.HapticFeedback is not available.");
  }
};

const handleClick = () => {
    triggerHapticFeedback();
    router.go(-1);
}

</script>

<style scoped>

.back-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.back-button {
    font-size: 13px;
    font-weight: 590;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border: 1px solid #ffffff33;
    border-radius: 30px;
    padding: 8px;
    margin: 8px;
}

.back-icon {
    width: 5px;
}

</style>