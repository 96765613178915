import axios from '@/utils/axiosConfig';

export default {
  namespaced: true,
  state: {
    winRecords: [], // Список выигрышей пользователя
    totalBalance: 0, // Общий баланс пользователя
  },
  getters: {
    winRecords: (state) => state.winRecords,
    totalBalance: (state) => state.totalBalance.toFixed(2),
  },
  mutations: {
    SET_WIN_RECORDS(state, records) {
      state.winRecords = records;
    },
    SET_TOTAL_BALANCE(state, balance) {
      state.totalBalance = balance;
    },
  },
  actions: {
    async fetchWinRecords({ commit }) {
      try {
        const response = await axios.post('/get-win/', {
        });

        const winRecordsData = response.data.win_records;

        if (winRecordsData && winRecordsData.length > 0) {
          // Группируем записи по `crypto_name`
          const groupedRecords = winRecordsData.reduce((acc, record) => {
            const { crypto_name, count, rate } = record;
            const existing = acc[crypto_name];

            if (existing) {
              existing.count += parseInt(count);
              existing.totalValue += parseFloat(rate) * parseInt(count);
            } else {
              acc[crypto_name] = {
                crypto_name,
                count: parseInt(count),
                rate: parseFloat(rate),
                totalValue: parseFloat(rate) * parseInt(count),
              };
            }

            return acc;
          }, {});

          const groupedArray = Object.values(groupedRecords);

          // Вычисляем общий баланс пользователя
          const totalBalance = groupedArray.reduce((acc, record) => {
            return acc + record.totalValue;
          }, 0);

          // Сохраняем данные в Vuex
          commit('SET_WIN_RECORDS', groupedArray);
          commit('SET_TOTAL_BALANCE', totalBalance);
        } else {
          commit('SET_WIN_RECORDS', []);
          commit('SET_TOTAL_BALANCE', 0);
        }
      } catch (error) {
        console.error('Error fetching win records:', error);
      }
    },
  },
};
