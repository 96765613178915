import { createStore } from 'vuex';
import axios from '../utils/axiosConfig';
import friends from './modules/friends';
import winRecords from './modules/wallet';

const store = createStore({
    modules: {
        friends,
        winRecords,
      },
    state: {
        boxesCount: 0,
    },
    mutations: {
        setBoxesCount(state, count) {
            state.boxesCount = count;
        },
        decrementBox(state) {
            if (state.boxesCount > 0) {
                state.boxesCount -= 1;
            }
        },
    },
    actions: {
        async fetchBoxesCount({ commit }) {
            try {
                const response = await axios.get('/user-boxes/');
                commit('setBoxesCount', response.data.unopened_boxes_count);
            } catch (err) {
                console.error('Error fetching unopened boxes count:', err);
            }
        },
    },
    getters: {
        boxesCount: (state) => state.boxesCount,
    },
});

export default store;
