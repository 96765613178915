/**
 * Триггер тактильного отклика через Telegram WebApp.
 * @param {string} type - Тип уведомления или воздействия. Поддерживаемые значения:
 *                        Для notificationOccurred: "success", "error", "warning".
 *                        Для impactOccurred: "light", "medium", "heavy", "soft", "rigid".
 * @param {string} mode - Режим работы. Возможные значения: "notification" (по умолчанию), "impact".
 */
export const triggerHapticFeedback = (type = "success", mode = "notification") => {
    if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
        const hapticFeedback = window.Telegram.WebApp.HapticFeedback;

        if (mode === "notification") {
            const supportedNotificationTypes = ["success", "error", "warning"];
            if (!supportedNotificationTypes.includes(type)) {
                console.warn(`Unsupported notification feedback type: ${type}`);
                return;
            }
            hapticFeedback.notificationOccurred(type);
        } else if (mode === "impact") {
            const supportedImpactTypes = ["light", "medium", "heavy", "soft", "rigid"];
            if (!supportedImpactTypes.includes(type)) {
                console.warn(`Unsupported impact feedback type: ${type}`);
                return;
            }
            hapticFeedback.impactOccurred(type);
        } else {
            console.warn(`Unsupported haptic feedback mode: ${mode}`);
        }
    } else {
        console.warn("Haptic feedback is not supported or WebApp.HapticFeedback is not available.");
    }
};