<template>

  <div class="friends-container">
    <div v-for="friend in friends" :key="friend.id">
      <div class="friend">
        <img :src="friend.photo_url" class="friend-img" />
        <p class="friend-name">@{{ friend.username }}</p>
      </div>
    </div>
  </div>

  <div v-if="freeBoxesLeft > 0" class="invite-container" :class="{ 'container-not-empty': friends.length > 0}">
    <!--контейнер с количеством оставшихся подарков-->
    <div class="gift-remained">
      <div class="gift-remained-text" v-if="friends.length === 0">
        Invite your frens for free boxes!
      </div>
      <div v-else-if="friends.length < 5 " class="gift-remained-text">
        <p>{{ getFreeBoxesLeftText() }}</p>
      </div>
      <div class="gift-icons-container">
        <img v-for="i in freeBoxesLeft" :key="i" class="gift-icon" :src="require('@/assets/gift-icon.png')" />
      </div>
    </div>

    <!--кнопка для вызова модального окна-->
    <button class="invite" @click="showModal = true">
      invite more friends!
    </button>

  </div>

  <InviteComponent :show="showModal" @close="showModal = false" @share="shareReferral" @copy="copyReferral"/>
</template>

<script setup>
import { ref, computed} from 'vue';
import InviteComponent from '../components/InviteComponent.vue';
import { useStore } from 'vuex';

const store = useStore();
const showModal = ref(false);
const friends = computed(() => store.getters['friends/friends']);
const freeBoxesLeft = computed(() => store.getters['friends/freeBoxesLeft'])

const getFreeBoxesLeftText = () => {
  return `You have ${freeBoxesLeft.value} free box${freeBoxesLeft.value === 1 ? '' : 'es'} left`;
}

const shareReferral = () => {
  console.log('Sharing referral');
  showModal.value = false;
};

const copyReferral = () => {
  console.log('Copying referral');
  showModal.value = false;
};

</script>

<style scoped>

.friends-container {
  gap: 8px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-bottom: 10px;
}

.gift-icons-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
}

.gift-icon {
  width: 40px;
  height: 40px;
}

.gift-remained {
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  gap: 8px;
  display: flex;
  flex-direction: column;
}

.invite-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 56px;
  padding: 40px 16px 16px 16px;
  background: #FFFFFF1A;
  border-radius: 20px;
}

.invite-container.container-not-empty {
  margin-top: auto;
}

.friend-img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.friend-name {
  font-weight: 400;
  font-size: 15px;
  color: #FFFFFF;
}

.friend {
  display: flex;
  flex-direction: row;
  gap: 16px;
  background: #FFFFFF1A;
  align-items: center;
  justify-content: flex-start;
  border-radius: 20px;
  padding: 12px 16px;
}

.invite {
    width: 318px;
    height: 54px;
    padding: 15px 0px;
    background: #6C47FF;
    color: white;
    border: none;
    border-radius: 12px;
    font-size: 17px;
    font-weight: 590;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .invite:hover {
    background: #5832f1;
  }
</style>  
