<template>
  <div class="total-balance-container">
    <p class="total-balance-title">Total balance</p>
    <div class="total-balance">
      <p class="dollar-sign">$</p>
      <p class="total-balance-value">{{ totalBalance }}</p>
    </div>
  </div>
  
  <div v-if="winRecords.length > 0" class="coins-container">
    <div class="coins-list">
      <div v-for="(record, index) in winRecords" :key="index" class="coin-record">
        <img :src="getCoinImage(record.crypto_name)" alt="Coin" class="coin-image" />
        <div class="coin-details">
          <div class="coin-name-container">
            <span class="coin-name">{{ record.crypto_name }}</span>
            <!-- Отображаем рейтинг монеты из cryptoConfig с помощью getCoinRate -->
            <div class="coin-stars">
              <div v-for="(i, idx) in getCoinRate(record.crypto_name)" :key="idx">
                <img class="star-icon" :src="require('@/assets/star-icon.svg')" alt="Star" />
              </div>
            </div>
          </div>
        
          <div class="coin-total-value">
            <span class="total-value">{{ record.totalValue.toFixed(4) }} $</span>
            <span class="coin-total-count">{{ record.count }} {{ record.crypto_name.toUpperCase() }}</span>
          </div>
        
        </div>
      </div>
    </div>
  </div>
  
  <div v-else class="no-win-records">
    <p>No win records found.</p>
  </div>

</template>
  
<script setup>
import { computed } from 'vue';
import cryptoConfig from '@/assets/cryptoConfig.json';
import { useStore } from 'vuex';

const store = useStore(); 
const winRecords = computed(() => store.getters['winRecords/winRecords']);
const totalBalance = computed(() => store.getters['winRecords/totalBalance']);

// Функция для получения изображений криптовалют
const getCoinImage = (cryptoName) => {
  return require(`@/assets/coins/${cryptoName.toLowerCase()}.png`);
};

// получаем рейтинг монеты
const getCoinRate = (cryptoName) => {
  const crypto = cryptoConfig.find(c => c.name === cryptoName);
  return crypto.rate;
};

</script>
  
<style scoped>

.no-win-records {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 590;
}

.coin-stars {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.coin-record, .coin-name-container {
  display: flex;
}

.coin-record {
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border: none;
  border-radius: 20px;
  background: #FFFFFF1A;
}

.coins-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.coin-image {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
}

.coin-details {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-left: 16px;
  justify-content: space-between;
}

.coin-name {
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 400;
}

.coin-count {
  color: #888;
}

.coin-name-container {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
}

.coins-container {
  overflow-x: auto;
}

.total-balance-container {
  border: 1px solid #FFFFFF1A;
  background: none;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  margin-bottom: 12px;
}

.total-balance-title {
  color: #FFFFFF80;
  font-size: 13px;
  font-weight: 400;
  margin: 12px 0px;
}

.dollar-sign {
  font-size: 28px;
  color: #FFFFFF80;
  font-weight: 700;
}

.total-balance {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.total-balance-value {
  color: #FFFFFF;
  font-size: 28px;
  font-weight: 700;
}

.coin-total-value {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.total-value {
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 400;
}

.coin-total-count {
  color: #FFFFFF80;
  font-size: 13px;
  font-weight: 400;
}

</style>
